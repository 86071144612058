import mustache from "mustache/mustache.mjs";

class Userstatus {
    #templates = {
        'user': '<div class="card {{statusclass}}" data-userid="{{uuid}}"><div class="card-content"><h4>{{fullname}} <span class="badge badge-light badge-ustatus">{{statustxt}}</span></h4><div class="tags"><span class="badge badge-light group">{{grouptxt}}</span><span class="u-entrance badge badge-primary date">{{dateentrance}}</span>\n' +
            '<svg aria-hidden="true" focusable="false" style="height: 20px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"></path></svg><span class="u-exit badge badge-primary date">{{dateexit}}</span><span class="badge badge-light ">{{text}}</span></div></div></div>'
    };
    #wrapper = null;
    // #users = {};

    constructor(params) {
        if (params === undefined) {
            console.warn('UsersStatus has no params');
            return false;
        }

        if (params.elementId === undefined) {
            console.warn('UsersStatus has no params [elementId]');
            return false;
        }
        this.#wrapper = document.getElementById(params.elementId);

        this.activate();
    }

    activate() {
        this.fetchUsers();
    };

    getTemplate(name) {
        return this.#templates[name];
    }

    fetchUsers() {
        fetch('/api/users/list.json', {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        }).then(response => response.json())
            .then(data => {
                this.renderUsers(data);
                this.startGettingStatus();
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    renderUsers(data) {
        data.forEach(user => {
            this.#wrapper.appendChild(this.renderUser(user));
        });

    }

    renderUser(user) {
        let rendered = mustache.render(this.getTemplate('user'), user);
        let el = document.createElement('div');
        el.innerHTML = rendered;
        return el;
    }

    startGettingStatus() {
        this.getUserStatus(1);
    }

    getUserStatus(timeCheck) {

        fetch('/api/users/status/' + timeCheck + '.json', {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        }).then(response => response.json())
            .then(data => {
                data.data.forEach(ustat => {
                    const row = document.querySelector('#UsersStatusList .card[data-userid="' + ustat.user_id + '"]');
                    // console.log(ustat);
                    // console.log(row);
                    const ustatusTxt = (ustat.exit === null) ? 'w biurze' : 'nieobecny';
                    const ustatusCls = (ustat.exit === null) ? 'logged' : 'loggedout';

                    if (row) {
                        row.querySelector('.badge-ustatus').innerHTML = ustatusTxt;
                        row.querySelector('.u-exit').innerHTML = (ustatusCls === 'logged' ? '...' : ustat.exit);
                        row.setAttribute('class', 'card ' + ustatusCls);
                        row.classList.add('animate-change-info');
                        setTimeout(() => {
                            document.querySelectorAll('#UsersStatusList .animate-change-info').forEach(el => {
                                el.classList.remove('animate-change-info');
                            });
                        }, 1500);
                    } else {
                        this.#wrapper.appendChild(this.renderUser({
                            dateentrance: ustat.entrance,
                            dateexit: ustat.exit ?? '...',
                            uuid: ustat.user_id,
                            fullname: ustat.user_name,
                            statustxt: ustatusTxt,
                            statusclass: ustatusCls,
                        }));
                    }
                });

                setTimeout(() => {
                    this.getUserStatus(data.last_time_check);
                }, 1500);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
}

export default Userstatus;