class Progressbar {
    #interval_max_counter = 0;
    #interval_counter = 0;
    #intervalTime = 1000;
    #interval = null;
    #item = null;
    #image = null;

    constructor(params) {

        if (params === undefined) {
            console.warn('ProgressBar has no params');
            return false;
        }
        if (isNaN(window.ProgressBarMaxTime)) {
            console.warn('ProgressBar has no params [maxTime]');
            return false;
        }

        if (params.imageId === undefined) {
            console.warn('ProgressBar has no params [imageId]');
            return false;
        }

        this.#image = document.getElementById(params.imageId);

        if (params.elementId === undefined) {
            console.warn('ProgressBar has no params [elementId]');
            return false;
        }
        this.#item = document.getElementById(params.elementId);
        // if (params.intervalMaxCounter)
        this.activate(window.ProgressBarMaxTime);
    }

    activate(maxTimeParam) {
        const maxTime = parseInt(maxTimeParam);
        let self = this;
        if (maxTime) {
            this.#interval_max_counter = Math.round(parseInt(maxTime * 1000) / this.#intervalTime);
        } else {
            this.#interval_counter = 0;
        }
        this.#interval = window.setInterval(function () {
            self.fillProgressBar()
        }, this.#intervalTime);
    };

    fillProgressBar() {
        if (this.#interval_counter <= this.#interval_max_counter) {
            const progressBarEl = this.#item.querySelector('div');
            let value = parseInt(100 * this.#interval_counter / this.#interval_max_counter);
            progressBarEl.style.width = value + '%';
            // progressBarEl.innerText = value + '%';
            this.#interval_counter++;
        } else {
            this.restart();
        }
    };

    afterDeactivate() {
        window.location.reload();
    };

    restart() {

        fetch('/pages/inout', {
            headers: {
                'Content-Type': 'html/text',
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
            .then(response => response.text())
            .then(data => {
                this.#image.setAttribute('src', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        window.clearInterval(this.#interval);
        this.activate();
    };

    deactivate() {
        if (!isNaN(this.#interval)) {
            window.clearInterval(this.#interval);
            this.afterDeactivate();
        }
    }
}

export default Progressbar;